import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Container from 'react-bootstrap/Container'
import Nav from 'react-bootstrap/Nav'
// import NavDropdown from 'react-bootstrap/NavDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUtensils } from '@fortawesome/free-solid-svg-icons';


const Header = () => {
  return (
    <div>
      <>
        <Navbar bg="dark" variant="dark" style={{ backgroundColor: '#FBF9EC'}}>
          <Container>
            <Navbar.Brand className="mx-auto" href='/'> <FontAwesomeIcon icon={faUtensils} size="1x" /> Στου Κίμωνα</Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link href="#addfood">Add Food</Nav.Link>
              {/* <Nav.Link href="#features"></Nav.Link>
              <Nav.Link href="#pricing"></Nav.Link> */}
            </Nav>
          </Container>
        </Navbar>
      </>
    </div>
  );
}


export default Header
