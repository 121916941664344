import React from "react";
// import Header from "../components/Header";
import Home from "../components/Home";
import ScrollToTop from "../components/ScrollToTop";
import "bootstrap/dist/css/bootstrap.min.css";
import "./main.css";

const Main = () => {
  return (
    <div>
      <>
        {/* <Header /> */}
        <Home />
        <ScrollToTop />
      </>
    </div>
  );
};

export default Main;