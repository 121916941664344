import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import ToggleButton from "react-bootstrap/ToggleButton";
import Dropdown from "react-bootstrap/Dropdown";
import Card from "react-bootstrap/Card";
import Modal from "react-modal"; // Import the Modal component
import { onSnapshot, collection } from "firebase/firestore";
import { db } from "../lib/init-firebase";


const Home = () => {
  const [radioValue, setRadioValue] = useState(null);
  const radios = [
    { name: "Καφέδες/Coffee", value: "1" },
    { name: "Ροφήματα/Beverages", value: "2" },
    { name: "Aναψυκτικά/Soft Drinks", value: "3" },
    { name: "Ορεκτικά/Appetizers", value: "4" },
    { name: "Κρεατικά/Meats", value: "5" },
    { name: "Ποτά/Drinks", value: "6" },
    { name: "Γλυκά/Desserts", value: "7" },
    // Add more categories as needed
  ];

  const [foods, setFoods] = useState([]);
  const [menu, setMenu] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const modalStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "20px",
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
      borderRadius: "8px",
      width: "300px",
      textAlign: "center",
    },
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };


  // Custom sorting function for Greek strings
  const greekStringComparator = (a, b) => a.localeCompare(b, "el", { sensitivity: "base" });

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "foods"), (snapshot) => {
      setFoods(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })));
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Filter foods based on the selected category
    const filteredFoods = radioValue
      ? foods.filter((food) => food.data.category === radioValue)
      : foods;

    // Sort the filteredFoods alphabetically by food title using the custom comparator
    const sortedFoods = [...filteredFoods].sort((a, b) => greekStringComparator(a.data.title, b.data.title));

    // Update the menu state with the sorted array
    setMenu(sortedFoods);
  }, [foods, radioValue]);

  const handleCategoryChange = (category) => {
    setRadioValue(category);
  };

  return (
    <>
      <section className="container mt-2">
      <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Category Selection"
          ariaHideApp={false}
          style={modalStyles} // Apply the custom styles to the modal
        >
          <h2 style={{ marginBottom: "15px", fontSize: 15 }}>Διάλεξε Κατηγορία/Select a Category</h2>
          <div>
            {radios.map((radio, idx) => (
              <div
                key={idx}
                onClick={() => {
                  handleCategoryChange(radio.name);
                  closeModal();
                }}
                style={{
                  padding: "10px",
                  cursor: "pointer",
                  borderBottom: "1px solid #ccc",
                }}
              >
                {radio.name}
              </div>
            ))}
          </div>
          <button
            onClick={closeModal}
            style={{
              marginTop: "15px",
              padding: "10px 20px",
              backgroundColor: "#6C757D",
              color: "#fff",
              border: "none",
              borderRadius: "4px",
              cursor: "pointer",
            }}
          >
            X
          </button>
        </Modal>
        <h2
          className="text-center mb-2"
          style={{ fontWeight: 600, color: "#212529", fontFamily: 'Roboto Mono' }}
        >
          Στου Κίμωνα
        </h2>
        <h3 className="text-center mb-2" style={{ fontFamily: 'Roboto Mono', fontSize: 20 }}>-Από το 1961-</h3>

        <div className="container" style={{}}>
          <div className="row">
            <div className="btn-container d-flex justify-content-center p-1">
              {/* <Button
                onClick={() => setRadioValue(null)}
                variant="primary"
                size="l"
                active
              >
                All
              </Button> */}
            </div>
            <div className="btn-container d-flex justify-content-center">
              {window.innerWidth <= 1092 ? (
                <Dropdown>
                  <Dropdown.Toggle variant="secondary">
                    Κατηγορίες/Categories
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {radios.map((radio, idx) => (
                      <Dropdown.Item
                        key={idx}
                        active={radioValue === radio.name}
                        onClick={() => handleCategoryChange(radio.name)}
                      >
                        {radio.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              ) : (
                <ButtonGroup className="mb-2">
                  {radios.map((radio, idx) => (
                    <ToggleButton
                      key={idx}
                      id={`radio-${idx}`}
                      type="radio"
                      variant="secondary"
                      name="radio"
                      value={radio.value}
                      checked={radioValue === radio.name}
                      onChange={() => handleCategoryChange(radio.name)}
                    >
                      {radio.name}
                    </ToggleButton>
                  ))}
                </ButtonGroup>
              )}
            </div>
          </div>
        </div>

        <div className="container mt-3" style={{ paddingBottom: 25 }}>
          <div className="row d-flex justify-content-center align-items-center">
            {menu.map((food) => {
              return (
                <Card
                  key={food.id}
                  style={{
                    // background: 'red'
                    height: 95,
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <Card.Body style={{ flexGrow: 1 }}>
                    <Card.Title>{food.data.title}</Card.Title>
                    <Card.Text style={{ fontWeight: "400" }}>{food.data.description}</Card.Text>
                  </Card.Body>
                  <footer
                    className="footer"
                    style={{ alignSelf: "flex-end", fontWeight: 500, marginBottom: 35 }}
                  >
                    {food.data.price}€
                  </footer>
                </Card>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
