import React, { useState } from "react";
import RealtimeFoods from '../components/RealtimeFoods';
import AddFood from '../components/AddFood';
import Header from "../components/Header";

const Admin = () => {
  const correctPassword = "kimonas";
  const [showAdminPage, setShowAdminPage] = useState(false);

  const handleAdminAccess = () => {
    const enteredPassword = window.prompt("Enter the admin password:");
    if (enteredPassword === correctPassword) {
      setShowAdminPage(true);
    } else {
      alert("Incorrect password. Access denied.");
    }
  };

  return (
    <div className="App">
      <Header />
      <header className="App-header">
        <h3>Admin Page</h3>
      </header>
      <main>
        {showAdminPage ? (
          <>
            <RealtimeFoods />
            <AddFood />
          </>
        ) : (
          <button onClick={handleAdminAccess}>Enter Admin Password</button>
        )}
      </main>
    </div>
  );
};

export default Admin;
