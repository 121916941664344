import React, { useState } from 'react';
import { addDoc } from 'firebase/firestore';
import { foodCollectionRef } from '../lib/firestore.collections';

export default function AddFood() {
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('Καφέδες/Coffee');
  const [description, setDescription] = useState('');

  const foodCategories = [
    { value: 'Καφέδες/Coffee', label: 'Καφέδες/Coffee' },
    { value: 'Ροφήματα/Beverages', label: 'Ροφήματα/Beverages' },
    { value: 'Aναψυκτικά/Soft Drinks', label: 'Aναψυκτικά/Soft Drinks' },
    { value: 'Ορεκτικά/Appetizers', label: 'Ορεκτικά/Appetizers' },
    { value: 'Κρεατικά/Meats', label: 'Κρεατικά/Meats' },
    { value: 'Ποτά/Drinks', label: 'Ποτά/Drinks' },
    { value: 'Γλυκά/Desserts', label: 'Γλυκά/Desserts' },
    // Add more categories as needed
  ];

  function handleSubmit(e) {
    e.preventDefault();
    if (title === '' || price === '' || category === '' || description === '') {
      alert('Είναι άδεια, γέμισε τα!');
      return;
    }
    // const foodsCollRef = collection(db, 'foods')
    addDoc(foodCollectionRef, { title, price, category, description })
      .then(response => {
        console.log(response.id);
        alert(`Προστέθηκε: ${title}`);
        // Clear form fields
        setTitle('');
        setPrice('');
        setCategory('Καφέδες/Coffee'); // Reset to default value
        setDescription('');
      })
      .catch(error => {
        console.log(error.message);
      });
  }

  return (
    <div style={{ paddingBottom: 50 }}>
      <br />
      <br />
      <h4 id='addfood'>Add Food</h4>
      <form onSubmit={handleSubmit}>
        <label htmlFor='title'>Food Title </label>
        <input type='text' value={title} onChange={e => setTitle(e.target.value)} />
        <br />
        <label htmlFor='price'>Food Price </label>
        <input type='text' value={price} onChange={e => setPrice(e.target.value)} />
        <br />
        <label htmlFor='category'>Food Category </label>
        <select
          id="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          {foodCategories.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <br />
        <label htmlFor='description'>Food Description (English) </label>
        <input type='text' value={description} onChange={e => setDescription(e.target.value)} />
        <br />
        <button type='submit'>Add Food</button>
      </form>
    </div>
  )
}
