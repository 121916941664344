import React, { useEffect, useState } from 'react';
import { onSnapshot, collection } from "firebase/firestore";
import { doc, deleteDoc } from 'firebase/firestore';
import { db } from '../lib/init-firebase';
import EditFood from './EditFood';
import Dropdown from "react-bootstrap/Dropdown";
import Card from "react-bootstrap/Card";
import ToggleButton from "react-bootstrap/ToggleButton";
import ButtonGroup from "react-bootstrap/ButtonGroup";

export default function RealtimeFoods() {
  const [foods, setFoods] = useState([]);
  const [selectedFood, setSelectedFood] = useState(null);

  const [radioValue, setRadioValue] = useState(null);
  const radios = [
    { name: "Καφέδες/Coffee", value: "1" },
    { name: "Ροφήματα/Beverages", value: "2" },
    { name: "Aναψυκτικά/Soft Drinks", value: "3" },
    { name: "Ορεκτικά/Appetizers", value: "4" },
    { name: "Κρεατικά/Meats", value: "5" },
    { name: "Ποτά/Drinks", value: "6" },
    { name: "Γλυκά/Desserts", value: "7" },
    // Add more categories as needed
  ];

  const [menu, setMenu] = useState([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "foods"), (snapshot) => {
      setFoods(snapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() })));
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    // Filter foods based on the selected category
    const filteredFoods = radioValue
      ? foods.filter((food) => food.data.category === radioValue)
      : foods;

    // Custom sorting function for Greek strings
    const greekStringComparator = (a, b) => a.localeCompare(b, "el", { sensitivity: "base" });

    // Sort the filteredFoods alphabetically by food title using the custom comparator
    const sortedFoods = [...filteredFoods].sort((a, b) => greekStringComparator(a.data.title, b.data.title));

    // Update the menu state with the sorted array
    setMenu(sortedFoods);
  }, [foods, radioValue]);

  function deleteFood(id) {
    const selectedFood = foods.find((food) => food.id === id);
    if (!selectedFood) {
      return;
    }

    const confirmDelete = window.confirm(`Are you sure you want to delete the food: ${selectedFood.data.title}?`);
    if (confirmDelete) {
      const docRef = doc(db, 'foods', id);
      deleteDoc(docRef)
        .then(() => console.log('deleted'))
        .catch((error) => console.log(error.message));
    }
  }

  function editFood(id) {
    const selectedFood = foods.find((food) => food.id === id);
    setSelectedFood(selectedFood);
  }

  const handleCategoryChange = (category) => {
    setRadioValue(category);
  };

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="btn-container d-flex justify-content-center p-1">
          </div>
          <div className="btn-container d-flex justify-content-center">
            {window.innerWidth <= 1092 ? (
              <Dropdown>
                <Dropdown.Toggle variant="secondary">
                  Κατηγορίες/Categories
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {radios.map((radio, idx) => (
                    <Dropdown.Item
                      key={idx}
                      active={radioValue === radio.name}
                      onClick={() => handleCategoryChange(radio.name)}
                    >
                      {radio.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            ) : (
              <ButtonGroup className="mb-2">
                {radios.map((radio, idx) => (
                  <ToggleButton
                    key={idx}
                    id={`radio-${idx}`}
                    type="radio"
                    variant="secondary"
                    name="radio"
                    value={radio.value}
                    checked={radioValue === radio.name}
                    onChange={() => handleCategoryChange(radio.name)}
                  >
                    {radio.name}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            )}
          </div>
        </div>
      </div>

      <div className="container mt-3">
        <div className="row d-flex justify-content-center align-items-center">
          {menu.map((food) => {
            return (
              <Card
                key={food.id}
                style={{
                  height: 80,
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <Card.Body style={{ flexGrow: 1 }}>
                  <Card.Title style={{ fontSize: 14 }}>
                    <button onClick={() => deleteFood(food.id)}>🗑️</button>
                    {food.data.title}
                    <button onClick={() => editFood(food.id)}><a href='#editfood'>✏️</a></button>
                  </Card.Title>
                  <Card.Text style={{ fontWeight: "400", fontSize: 13 }}>{food.data.description}</Card.Text>
                </Card.Body>
                <footer
                  className="footer"
                  style={{ alignSelf: "flex-end", fontWeight: 500, marginBottom: 20 }}
                >
                  {food.data.price}€
                </footer>
              </Card>
            );
          })}
        </div>
      </div>
      {selectedFood && <EditFood food={selectedFood} />}
    </div>
  );
}
