// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

import {getFirestore} from 'firebase/firestore'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyB_brUPQfxK08I1Ipbm7_dGvy07AozSBvE",
  authDomain: "crud-menu-858f1.firebaseapp.com",
  projectId: "crud-menu-858f1",
  storageBucket: "crud-menu-858f1.appspot.com",
  messagingSenderId: "821046420581",
  appId: "1:821046420581:web:1a323a96ac5fdbbe8b128c",
  measurementId: "G-6J2HT0XXGJ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app)