import React, { useState, useEffect } from 'react';
import { db } from '../lib/init-firebase';
import { doc, updateDoc } from 'firebase/firestore';

export default function EditFood({ food }) {
  const [title, setTitle] = useState('');
  const [id, setId] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (food) {
      setTitle(food.data.title);
      setId(food.id);
      setPrice(food.data.price);
      setCategory(food.data.category);
      setDescription(food.data.description);
    }
  }, [food]);

  const foodCategories = [
    { value: 'Καφέδες/Coffee', label: 'Καφέδες/Coffee' },
    { value: 'Ροφήματα/Beverages', label: 'Ροφήματα/Beverages' },
    { value: 'Aναψυκτικά/Soft Drinks', label: 'Aναψυκτικά/Soft Drinks' },
    { value: 'Ορεκτικά/Appetizers', label: 'Ορεκτικά/Appetizers' },
    { value: 'Κρεατικά/Meats', label: 'Κρεατικά/Meats' },
    { value: 'Ποτά/Drinks', label: 'Ποτά/Drinks' },
    { value: 'Γλυκά/Desserts', label: 'Γλυκά/Desserts' },
    // Add more categories as needed
  ];

  function handleSubmit(e) {
    e.preventDefault();
    if (title === '' || id === '') {
      return;
    }
    const docRef = doc(db, 'foods', id);
    updateDoc(docRef, { title, price, category, description })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => console.log(error.message));
  }

  // async function handleEdit() {
  //   if (id === '') {
  //     return;
  //   }
  //   const docRef = doc(db, 'foods', id);
  //   try {
  //     const docSnap = await getDoc(docRef);
  //     if (docSnap.exists()) {
  //       const data = docSnap.data();
  //       setTitle(data.title);
  //       setPrice(data.price);
  //       setCategory(data.category);
  //       setDescription(data.description);
  //     } else {
  //       console.log('No such document exists!');
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // }

  return (
    <div style={{ paddingTop: 20 }}>
      <h4 id='editfood'>Edit Food</h4>
      <form onSubmit={handleSubmit}>
        <label htmlFor="id">Food ID </label>
        <input
          id="id"
          type="text"
          value={id}
          readOnly
          style={{ backgroundColor: '#f2f2f2', color: '#999', cursor: 'not-allowed' }}
          onChange={(e) => setId(e.target.value)}
        />
        {/* <button type="button" onClick={handleEdit}>
          Edit
        </button> */}
        <br />
        <label htmlFor="title">Food Title </label>
        <input
          id="title"
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <br />
        <label htmlFor="title">Food Price </label>
        <input
          id="price"
          type="text"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <br />
        <label htmlFor="title">Food Category </label>
        <select
          id="category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        >
          {foodCategories.map((option) => (
            <option key={option.value} value={option.value} selected={category === option.value}>
              {option.label}
            </option>
          ))}
        </select>

        <br />
        <label htmlFor="title">Food Title (English) </label>
        <input
          id="description"
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <br />
        <button type="submit">Update Food</button>
      </form>
    </div>
  );
}
